import PropTypes from "prop-types";
import { useContent } from "fusion-content";
import { SecondaryNav } from "@washingtonpost/site-secondary-nav";
import { getClasses } from "@washingtonpost/front-end-utils";

const buildSectionData = (children) =>
  children.map(
    (
      {
        _id,
        children: innerChildren = [],
        display_name: displayName,
        name,
        navigation = {},
        site: { site_url: siteUrl } = {},
        url
      },
      index
    ) => {
      return {
        _id: _id || `${displayName || name}-${index}`,
        name: displayName || navigation?.nav_title || name,
        url: url || siteUrl,
        ...(innerChildren.length > 0 && {
          children: buildSectionData(innerChildren) // Recursion for the win
        })
      };
    }
  );

const TopperSecondaryNav = ({ alignment, url: currentUrl, section }) => {
  const secondaryNavContent = useContent({
    source: "secondary-nav-by-id",
    query: {
      section
    }
  });

  const { children = [] } = secondaryNavContent;
  if (children.length === 0) return null;

  return (
    <SecondaryNav
      alignment={alignment}
      className={getClasses("", {
        "justify-center-hpt": alignment === "center"
      })}
      currentSection={currentUrl}
      sectionData={{
        children: buildSectionData(children)
      }}
      useGutter={false}
    />
  );
};

TopperSecondaryNav.propTypes = {
  alignment: PropTypes.string,
  section: PropTypes.string,
  url: PropTypes.string
};

export default TopperSecondaryNav;
